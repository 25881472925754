body {
  max-width: 1920px;
  background-color: rgb(255, 255, 255);
  margin: 0px;
  min-height: 100%;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#header {
  background: rgb(63, 81, 181);
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(5, 0, 88, 1) 0%,
    rgba(63, 81, 181, 1) 20%,
    rgba(63, 81, 181, 1) 80%,
    rgba(5, 0, 88, 1) 100%
  );
  height: 140px;
  display: flex;
}

#header2 {
  height: 140px;
  width: 100%;
  display: flex;
  margin: auto;
}

#root {
  height: 70%;
}

#our_logo {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 20px;
  max-width: 100px;
  width: 15%;
}

.collapsible:empty {
  display: none;
}

#name {
  margin-top: auto;
  margin-bottom: auto;
  color: rgb(255, 255, 255);
  min-width: 210px;
}

#name2 {
  height: 100%;
  color: rgb(255, 255, 255);
  min-width: 220px;
  padding-left: 20px;
}

#aboutus {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 20px;
  color: rgb(255, 255, 255);
  min-width: 78px;
}

#lang_switcher {
  float: right;
  margin-left: auto;
}
